const React = require('react');
const { Provider } = require('react-redux');
const { createStore } = require('./src/redux/ReduxWrapper');
const { PageBackground } = require('./src/components/PageBackground');

exports.wrapRootElement = ({ element, ...props }) => {
  return (
    <Provider store={createStore()}>
      <PageBackground id='8080-bg' />
      {element}
    </Provider>
  );
};

exports.onRouteUpdate = ({ location, prevLocation }) => {
  const bg = document.getElementById('8080-bg');

  if (prevLocation) {
    bg.classList.remove('no-animate-invert');
    bg.classList.remove('no-animate-index');
    document.body.classList.add('body-anim');
    document.body.classList.remove('body-color-no-anim');
    if (
      location.pathname === '/' &&
      (prevLocation.pathname === '/about/' ||
        prevLocation.pathname === '/about' ||
        prevLocation.pathname === '/work/' ||
        prevLocation.pathname === '/work')
    ) {
      bg.classList.remove('animate-invert');
      bg.classList.add('animate-index');
      document.body.classList.remove('body-color');
    } else if (
      (location.pathname === '/about/' ||
        location.pathname === '/about' ||
        location.pathname === '/work/' ||
        location.pathname === '/work') &&
      prevLocation.pathname === '/'
    ) {
      bg.classList.remove('animate-index');
      bg.classList.add('animate-invert');
      document.body.classList.add('body-color');
    } else if (
      (location.pathname.startsWith('/about') && prevLocation.pathname.startsWith('/work')) ||
      (location.pathname.startsWith('/work') && prevLocation.pathname.startsWith('/about'))
    ) {
      bg.classList.remove('animate-index');
      bg.classList.remove('animate-invert');
      bg.classList.add('no-animate-invert');
      document.body.classList.add('body-color');
    }
  } else {
    if (location.pathname === '/') {
      bg.classList.add('no-animate-index');
    } else if (
      location.pathname === '/about/' ||
      location.pathname === '/about' ||
      location.pathname === '/work/' ||
      location.pathname === '/work'
    ) {
      bg.classList.add('no-animate-invert');
      document.body.classList.add('body-color-no-anim');
    }
  }
};

exports.shouldUpdateScroll = ({ routerProps, prevRouterProps, getSavedScrollPosition }) => {
  if (
    routerProps.location.pathname === 'about' ||
    routerProps.location.pathname === '/about/' ||
    routerProps.location.pathname === 'work' ||
    routerProps.location.pathname === '/work/'
  ) {
    return true;
  }

  if (typeof window !== 'undefined') {
    return [0, window.innerHeight * 12];
  } else {
    return [0, 2000];
  }
};
