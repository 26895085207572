import React, { useEffect, useState, useRef } from 'react';
import styled, { keyframes } from 'styled-components';

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  height: 100%;
  min-height: 100%;
`;

const Inner = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  height: calc(50vh - 140px);
  width: 100%;
  background-color: var(--secondary);
  box-shadow: 0px 0px 91px 122px var(--secondary);
  opacity: 0;
  transform-origin: top;
  transition: transform 0.5s ease-in-out;

  .animate-invert & {
    transform: scaleY(0);
    opacity: 1;
    transition: transform 0.4s ease-in-out, height 0.5s ease-in-out 1s;
  }

  .animate-index & {
    transform: scaleY(1);
    opacity: 1;
  }

  .no-animate-index & {
    transform: scaleY(1);
    opacity: 1;
  }

  .no-animate-invert & {
    transform: scaleY(0);
    opacity: 1;
  }
`;

export const PageBackground = ({ id, ...props }) => {
  const [innerHeight, setInnerHeight] = useState('50%');

  useEffect(() => {
    window.addEventListener('scroll', e => onScroll(e));

    setInnerHeight(parseInt(window.scrollY * -0.4) + parseInt(window.innerHeight) - 150);

    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  const onScroll = e => {
    setInnerHeight(parseInt(window.scrollY * -0.4) + parseInt(window.innerHeight) - 150);
  };

  return (
    <Wrapper id={id}>
      {
        // <Inner style={{height: innerHeight}}></Inner>
      }
      <Inner></Inner>
    </Wrapper>
  );
};
