import { combineReducers } from 'redux';
import * as grid from '../helpers/grid';

const CELL_SIZE = 20;
const CELL_WIDTH =  (typeof window !== 'undefined' && window.matchMedia('(max-width: 700px)').matches) ?  20 : 50;
const CELL_HEIGHT = (typeof window !== 'undefined' && window.matchMedia('(max-width: 700px)').matches) ? 20 : 50;
// const CELL_WIDTH = CELL_SIZE;
// const CELL_HEIGHT = CELL_SIZE;

const GRID_HEIGHT = typeof window !== 'undefined' ? parseInt(window.innerHeight / CELL_HEIGHT) : 25;
const GRID_WIDTH = typeof window !== 'undefined' ? parseInt(window.innerWidth / CELL_WIDTH) : 40;
const initialGrid = grid.makeGrid(GRID_HEIGHT,GRID_WIDTH);

export const boardReducer = (state = initialGrid, action) => {
  switch(action.type){
    case 'TOGGLE_ALIVE':
      let board = state.slice(0);
      if (board[action.x] && board[action.x][action.y]) {

        let cell = board[action.x][action.y];
        if (cell) {
          cell.status = !cell.status;
          cell.newBorn = !cell.newBorn;
          cell.content = cell.content;
        } 
      }
      return board;
    case 'TICK':
      return grid.advanceGrid(state.slice(0));
    default:
      return state;
  }
};


const rootReducer = combineReducers({
  board: boardReducer,
});

export default rootReducer;